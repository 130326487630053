import styled from "styled-components";

import { img404 } from "../../images";

const Erreur = () => {
    return (
        <Wrapper>
            <Image404>
            </Image404>
            <p>404 Error</p>
        </Wrapper>
    )
}

const Wrapper = styled.main`
    padding: 0;
    p {
        font-size: xx-large;
        margin-top: -100px;
        text-align: center;
    }
`

const Image404 = styled.div`
    background: url(${img404}) center no-repeat;
    height: 400px;
    opacity: 0.2;
    /* position: sticky; */
    width: 100%;
`

export default Erreur;