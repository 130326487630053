import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import FAQ1 from "./comp/FAQ1";
import FAQ2 from "./comp/FAQ2";
import FAQ3 from "./comp/FAQ3";

const Section3 = () => {
    return (
        <Wrapper>
            <h2><FontAwesomeIcon icon={faCircleQuestion} /> <span>Foire aux questions</span></h2>
            <FAQ1 />
            <FAQ2 />
            <FAQ3 />
        </Wrapper>
    )
}

const Wrapper = styled.section`
    details {
        margin: 20px 0 10px;
        summary {
            background-color: var(--c2);
            border-radius: 10px 10px 0 0;
            cursor: pointer;
            padding: 20px;
        }
        p {
            background-color: var(--c4);
            border-radius: 0 0 10px 10px;
            color: var(--c1);
            margin: 0;
            padding: 20px;
            a {
                color: var(--c0);
            }
        }
    }
`

export default Section3;