import styled from "styled-components";

import imgAcc from "../../images/panorama-montreal.webp";

const ImgAcc = () => {
    return (
        <Wrapper>
            <h1>Tours Explore Montréal</h1>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background: url(${imgAcc}) center no-repeat;
    background-size: cover;
    height: 200px;
    h1 {
        margin: 0;
        opacity: 0;
    }
`

export default ImgAcc;