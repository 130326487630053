import styled from "styled-components";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { LangContext } from "../../LangContext";

const Pied = () => {

    const { setMenuOuvert, setMenuLangOuvert } = useContext(LangContext);

    const fermerMenus = () => {
        setMenuOuvert(false);
        setMenuLangOuvert(false);
    }

    return (
        <Wrapper>
            <nav>
                <Link onClick={fermerMenus} to="apropos">About</Link>
                <Link onClick={fermerMenus} to="conditions">Terms and Conditions</Link>
            </nav>
            <p><FontAwesomeIcon icon={faCopyright} /> <span>Tours Explore Montréal with Nicola Zoghbi</span></p>
        </Wrapper>
    )
}

const Wrapper = styled.footer`
    align-items: center;
    background-color: var(--c3);
    color: var(--c6);
    display: flex;
    flex-wrap: wrap;
    gap: 5px 20px;
    justify-content: space-between;
    padding: 10px;
    nav {
        display: flex;
        gap: 10px;
        a {
            color: var(--c6);
        }
    }
    p {
        margin: 0;
    }
`

export default Pied;