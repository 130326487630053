import { ExternalLink } from "react-external-link";

const FAQ3 = () => {
    return (
        <div>
            <h3>Allgemeines</h3>
            <details>
                <summary>Was sollte ich als typisches Gericht in Montreal probieren?</summary>
                <p>Es gibt <ExternalLink href="https://de.wikipedia.org/wiki/Poutine">poutine</ExternalLink>, <ExternalLink hrefLang="en" href="https://en.wikipedia.org/wiki/Montreal-style_smoked_meat">smoked meat</ExternalLink> und natürlich <ExternalLink hrefLang="en" href="https://en.wikipedia.org/wiki/Montreal-style_bagel">unsere bagels</ExternalLink>. Aber vor allem die Poutine!</p>
            </details>
            <details>
                <summary>Wann ist die beste Zeit, um Montreal zu bereisen? Welche Temperaturen kann ich erwarten?</summary>
                <p>Zwischen Mai und September, wobei Sie die Hitzewellen im Juli und Anfang August möglichst meiden sollten. Um die Farben in den Bäumen zu sehen, wählt man den Anfang des Oktobers. Wenn Sie Montreal im Schnee erleben möchten, sollten Sie zwischen Weihnachten und Ende Februar kommen. Beachten Sie <ExternalLink href="https://de.weatherspark.com/y/25077/Durchschnittswetter-in-Montreal-Kanada-das-ganze-Jahr-%C3%BCber"> die Durchschnittswerte in Montreal</ExternalLink>, um die normalen Bedingungen während Ihres Aufenthalts zu kennen.</p>
            </details>
        </div>
    )
}

export default FAQ3;