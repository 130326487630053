import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";

const MenuLang = () => {

    const [montrerLang, setMontrerLang] = useState(false);

    const ouvrirMenuLing = () => setMontrerLang(true);
    const fermerMenuLing = () => setMontrerLang(false);

    return (
        <Wrapper onMouseEnter={ouvrirMenuLing} onMouseLeave={fermerMenuLing}>
            <button title="change language">
                <FontAwesomeIcon icon={faEarthAmericas} size="2x" />
            </button>
            {
                montrerLang && <ul>
                    <li>
                        <Link aria-label="français" to="/fr">FR</Link>
                    </li>
                    <li>
                        <Link aria-label="deutsch" to="/de">DE</Link>
                    </li>
                </ul>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    button {
        height: 45px;
        align-self: self-end;
    }
    ul {
        background-color: var(--c3);
        border: 1px solid var(--c2);
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 5px;
        li {
            padding: 5px;
            a {
                color: var(--c6);
                padding: 5px;
                text-decoration: none;
            }
            &:hover {
                font-weight: bold;
            }
        }
    }
`

export default MenuLang;