import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import Entete from "./1. Entete/Entete";
import Corps from "./2. Corps/Corps";
import Pied from "./3. Pied/Pied";

import APropos from "./9. Autres pages/APropos";
import Conditions from "./9. Autres pages/Conditions";
import Erreur from "./9. Autres pages/404";

const AppFr = () => {

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta property="og:title" content="Tours Explore Montréal : guided tours" />
                <meta property="og:description" content="Guided tours in Montreal" />
                <meta name="description" content="Book a guided tour in Montreal with a local guide" />
            </Helmet>
            <Wrapper>
                <Entete />
                <Routes>
                    <Route path="/apropos" element={<APropos />} />
                    <Route path="/conditions" element={<Conditions />} />
                    <Route path="/" element={<Corps />} />
                    <Route path="/*" element={<Erreur />} />
                </Routes>
                <Pied />
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    background-color: var(--c1);
    min-height: 100vh;
`

export default AppFr;