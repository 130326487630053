import styled from "styled-components";
import { Link } from "react-router-dom";

const Choix = () => {
    return (
        <Wrapper>
            <li>
                <Link to="/fr">
                    <span>Français</span>
                </Link>
            </li>
            <li>
                <Link to="/en">
                    <span>English</span>
                </Link>
            </li>
            <li>
                <Link to="/de">
                    <span>Deutsch</span>
                </Link>
            </li>
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 15px;
    li {
        background-color: var(--c2);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 10px 15px;
        width: 90px;
        a {
            color: var(--c5);
            text-decoration: none;
        }
    }
    @media screen and (max-width: 350px) {
        flex-direction: column;
        align-items: center;
    }
`

export default Choix;