import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import FormContact from "./comp/FormContact";
import FormMerci from "./comp/FormMerci";

const Section4 = () => {

    const [montrerForm, setMontrerForm] = useState(true);

    return (
        <Wrapper>
            <h2><FontAwesomeIcon icon={faEnvelope} /> <span>Contact</span></h2>
            <p>Vous souhaitez effectuer une réservation? Vous avez une question par rapport à un des tours offerts?</p>
            {
                montrerForm && <FormContact setMontrerForm={setMontrerForm} />
            }
            {
                montrerForm === false && <FormMerci />
            }
        </Wrapper>
    )
}

const Wrapper = styled.section``

export default Section4;