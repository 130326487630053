import { Element } from "react-scroll";

import Section1 from "./S1/Section1";
import Section2 from "./S2/Section2";
import Section3 from "./S3/Section3";
import Section4 from "./S4/Section4";

const Corps = () => {
    return (
        <main>
            <Element name="section1">
                <Section1 />
            </Element>
            <Element name="section2">
                <Section2 />
            </Element>
            <Element name="section3">
                <Section3 />
            </Element>
            <Element name="section4">
                <Section4 />
            </Element>
        </main>
    )
}

export default Corps;