import { ExternalLink } from "react-external-link";

const FAQ3 = () => {
    return (
        <div>
            <h3>Généralités</h3>
            <details>
                <summary>Qu'est-ce que je devrais essayer comme plat typique à Montréal?</summary>
                <p>Il y a <ExternalLink href="https://fr.wikipedia.org/wiki/Poutine_(plat)">la poutine</ExternalLink>, <ExternalLink href="https://fr.wikipedia.org/wiki/Smoked_meat">le smoked meat</ExternalLink> et bien sûr <ExternalLink href="https://fr.wikipedia.org/wiki/Bagel_de_Montr%C3%A9al">nos bagels</ExternalLink>. Mais avant tout, la poutine!</p>
            </details>
            <details>
                <summary>Quel est le meilleur moment pour visiter Montréal? À quelle température pourrais-je m'y attendre?</summary>
                <p>Entre mai et septembre, tout en évitant si possible la période des canicules en juillet et en début aout. Pour voir les couleurs dans les arbres, on choisit le début du mois d'octobre. Pour Montréal sous la neige, on vient plutôt entre Noël et la fin février. Voir les <ExternalLink href="https://fr.weatherspark.com/y/25077/M%C3%A9t%C3%A9o-moyenne-%C3%A0-Montr%C3%A9al-Canada-tout-au-long-de-l'ann%C3%A9e">moyennes à Montréal</ExternalLink> pour connaitre les conditions normales durant votre séjour.</p>
            </details>
        </div>
    )
}

export default FAQ3;