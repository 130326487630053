import styled from "styled-components";

import { imgAP1 } from "../../images/a-propos";

const APropos = () => {
    return (
        <main>
            <h2>À propos</h2>
            <p>Je suis un guide touristique certifié, et ce, depuis 2015. Mon numéro de permis est 7868.</p>
            <p>Mon quartier préféré est Le Plateau–Mont-Royal. J'aime aussi Rosemont et Villeray.</p>
            <p>J'adore marcher et me balader à vélo à travers Montréal, découvrant de nouveaux endroits.</p>
            <p>J'apprécie Montréal pour sa diversité culturelle, ses initiatives citoyennes et sa nature. Oui oui, la présence de nombreux parcs et des cours d'eau qui l'entoure rend la ville plus agréable, hiver comme été.</p>
            <p>Mes moments préférés pour profiter de ma ville? En mai lorsque les Montréalais se réveillent d'un long hiver, en aout pour profiter de l'été une dernière fois, et durant la saison froide, le lendemain d'une tempête de neige.</p>
            <p>Lorsque je ne guide pas, je suis enseignant. J'aime le contact avec les gens.</p>
            <p>Au plaisir de vous montrer ma ville!</p>
            <p>Nicola Z.</p>
            <ImagesMoi>
                <img alt="moi avec rivière" src={imgAP1} />
            </ImagesMoi>
        </main>
    )
}

const ImagesMoi = styled.div`
    background-color: var(--c2);
    display: flex;
    justify-content: center;
    img {
        max-width: 250px;
    }
`

export default APropos;