import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from 'html-react-parser';

import { faArrowRightToCity, faComment } from "@fortawesome/free-solid-svg-icons";
import * as imgTours from "../../../../images/index";
import BoiteReserv from "./BoiteReserv";
import ImagesEtDetails from "./ImagesEtDetails";

const Tour = ({ item }) => {

    const [montrerReserv, setMontrerReserv] = useState(false);

    const manipReserv = () => setMontrerReserv(true);
    
    return (
        <Wrapper>
            <summary title="öffnen / schließen Details">
                <img alt={item.imageVedette.alt} src={imgTours[item.imageVedette.src]} />
                <div>
                    <h3>{item.titre}</h3>
                    <p>{item.description}</p>
                </div>
            </summary>
            <div>
                <h4><FontAwesomeIcon icon={faArrowRightToCity} /> <span>Wichtigste Sehenswürdigkeiten</span></h4>
                <ul>
                    {
                        item.attraits.map((itemN, indexN) => {
                            return (
                                <li key={indexN}>{itemN}</li>
                            )
                        })
                    }
                </ul>
                <h4><FontAwesomeIcon icon={faComment} /> <span>Beschreibung</span></h4>
                <div>{parse(item.details)}</div>
                <ImagesEtDetails item={item} />
                {
                    montrerReserv === false &&
                    <BoutonsRouge onClick={manipReserv}>Buchen</BoutonsRouge>
                }
                {
                    montrerReserv &&
                    <BoiteReserv tour={item} />
                }
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.details`
    background-color: var(--c5);
    border-radius: 10px;
    color: var(--c1);
    padding: 10px;
    summary {
        cursor: pointer;
        list-style: none;
        width: 100%;
        > img {
            width: 100%;
        }
        div p {
            font-style: italic;
        }
    }
    @media screen and (min-width: 500px) and (max-width: 700px) {
        padding: 20px;
    }
    @media screen and (min-width: 700px) {
        padding: 40px;
        summary {
            display: flex;
            gap: 20px;
            > img {
                max-width: 500px;
                width: 50%;
            }
            > div {
                align-self: center;
                width: 50%;
            }
        }
    }
    a {
        color: var(--c0);
    }
`

const BoutonsRouge = styled.button`
    background-color: var(--c4);
    border-radius: 5px;
    color: var(--c1);
    cursor: pointer;
    display: block;
    margin: 10px auto;
    padding: 5px 10px;
    &:hover {
        background-color: var(--c3);
        color: var(--c6);
    }
`

export default Tour;