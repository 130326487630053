import styled from "styled-components";
import { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import LogoJS from "./FRA/1. Entete/Logo";

const charger = keyframes`
    0% {transform: rotate(0)};
    25% {transform: rotate(90deg)};
    50% {transform: rotate(180deg)};
    75% {transform: rotate(270deg)};
    100% {transform: rotate(360deg)};
`

const Chargement = () => {
    return (
        <Wrapper>
            <LogoJS />
            <FontAwesomeIcon icon={faSpinner} size="2x" />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;
    > :last-child {
        animation: ${charger} 1s linear infinite;
    }
`

export default Chargement;