import { ExternalLink } from "react-external-link";

const FAQ2 = () => {
    return (
        <div>
            <h3>Buchung</h3>
            <details>
                <summary>Sollte ich über ToursByLocals oder direkt bei Ihnen buchen?</summary>
                <p>Die ToursByLocals-Plattform bietet Ihnen die Möglichkeit, meinen Verfügbarkeitskalender einzusehen, zwischen zwei Stornierungsrichtlinien zu wählen und Zugang zu einem Ersatzguide zu haben, falls Ihr Guide aus dringenden Gründen absagen muss. Aus diesem Grund ist dies der bevorzugte Weg, außer bei kurzfristigen Buchungen (weniger als 48 Stunden), bei denen Sie vor Ort bezahlen können.</p>
            </details>
            <details>
                <summary>Gibt es eine Möglichkeit, eine Rückerstattung zu erhalten, wenn unser Flug abgesagt ist?</summary>
                <p>Ja, und zwar unabhängig davon, wie Sie Ihre Tour gebucht haben. Wenn Sie über die Plattform ToursByLocals bezahlt haben, lesen Sie <ExternalLink hrefLang="en" href="https://www.toursbylocals.com/Cancellation-Policy-Overview">ihre Stornierungsrichtlinien (nur auf Englisch)</ExternalLink> für weitere Details.</p>
            </details>
            <details>
                <summary>Ich habe eine Tour durch das Plateau gebucht, aber es soll viel Regen geben. Kann ich stattdessen den Besuch der unterirdischen Stadt machen?</summary>
                <p>Das ist eine gute Idee! Und auf jeden Fall! Die Differenz der beiden Touren wird Ihnen zurückerstattet, wenn Sie bereits bezahlt haben.</p>
            </details>
        </div>
    )
}

export default FAQ2;