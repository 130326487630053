import { ExternalLink } from "react-external-link";

const FAQ3 = () => {
    return (
        <div>
            <h3>General info</h3>
            <details>
                <summary>What's a typical Montreal dish I should try?</summary>
                <p>There's <ExternalLink href="https://en.wikipedia.org/wiki/Poutine">poutine</ExternalLink>, <ExternalLink href="https://en.wikipedia.org/wiki/Montreal-style_smoked_meat">smoked meat</ExternalLink>, and of course <ExternalLink href="https://en.wikipedia.org/wiki/Montreal-style_bagel">our bagels</ExternalLink>. But first and foremost, poutine!</p>
            </details>
            <details>
                <summary>When is the best time to visit Montreal? What temperature can I expect?</summary>
                <p>Between May and September, avoiding if possible the heatwaves of July and early August. To see the foliage in full color, opt for early October. For snow-covered Montreal, come between Christmas and the end of February. See the <ExternalLink href="https://weatherspark.com/y/25077/Average-Weather-in-Montr%C3%A9al-Canada-Year-Round">Montreal weather averages</ExternalLink> for normal conditions during your stay.</p>
            </details>
        </div>
    )
}

export default FAQ3;