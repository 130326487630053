import styled from "styled-components";
import { useEffect } from "react";
import { useForm } from '@formspree/react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Prenom from "./Prenom";
import Nom from "./Nom";
import Courriel from "./Courriel";
import Message from "./Message";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

const FormContact = ({ setMontrerForm }) => {

    const [form, manipForm] = useForm("mkndwwvj");

    useEffect(() => {
        if (form.succeeded) {
            setMontrerForm(false);
        }
    },[form.succeeded, setMontrerForm])

    return (
        <form
            onSubmit={manipForm}
            target="blank"
        >
            <Champ>
                <legend>Please fill the form below.</legend>
                <div>
                    <input
                        name="subject"
                        type="hidden"
                        value="Nouvelle demande de {{ prenom }} {{ nomF }}"
                    />
                    <Prenom />
                    <Nom />
                    <Courriel />
                    <Message />
                    <button type="submit">
                        <FontAwesomeIcon icon={faPaperPlane} /> 
                        <span>Send</span>
                    </button>
                </div>
            </Champ>
        </form>
    )
}

const Champ = styled.fieldset`
    border-radius: 5px;
    legend {
        text-align: center;
    }
    > div {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 20px 10px;
        width: 100%;
        button {
            border-radius: 5px;
            cursor: pointer;
            padding: 5px 10px;
        }
        > * {
            align-self: center;
        }
    }
`

export default FormContact;