import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tours from "./tours.json";
import { faPersonWalkingArrowLoopLeft } from "@fortawesome/free-solid-svg-icons";
import Tour from "./comp/Tour";

const Section2 = () => {

    return (
        <section>
            <h2><FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} /> <span>Choose one of these adventures.</span></h2>
            <TousTours>
                {
                    tours.map((item, index) => {
                        return (
                            <Tour key={index} item={item} />
                        )
                    })
                }
            </TousTours>
        </section>
    )
}

const TousTours = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
`

export default Section2;