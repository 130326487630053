import { ExternalLink } from "react-external-link";

const Conditions = () => {
    return (
        <main>
            <h2>Nutzungsbedingungen</h2>
            <p>Beim Senden einer Nachricht an den Autor auf dieser Website werden die vom Nutzer angegebenen Informationen weitergeleitet. Die Nachrichten werden über Formspree weitergeleitet <ExternalLink hrefLang="en" href="https://formspree.io/legal/privacy-policy/">(Datenschutzrichtlinie)</ExternalLink>.</p>
            <p>Einige grundlegende Informationen werden von <ExternalLink hrefLang="en" href="https://www.goatcounter.com/">GoatCounter</ExternalLink>, einer datenschutzfreundlichen Alternative zu Google Analytics, gesammelt.</p>
            <p>Bitte beachten Sie, dass das Navigieren auf anderen Websites davon abhängig ist, dass Sie deren Bedingungen akzeptieren. Der Autor dieser Website übernimmt diesbezüglich keine Verantwortung.</p>
        </main>
    )
}

export default Conditions;