import { createContext, useState, useEffect } from "react";

export const LangContext = createContext();

const LangContextProvider = ({ children }) => {

    const [menuOuvert, setMenuOuvert] = useState(false);

    useEffect(() => {
        const choixLing = navigator.languages;
        let langPref;
        for (let l = 0; l < choixLing.length; l++) {
            const choix = choixLing[l]
            if (choix.startsWith("fr")) {
                langPref = "fr";
                break;
            } else if (choix.startsWith("en")) {
                langPref = "en";
                break;
            } else if (choix.startsWith("de")) {
                langPref = "de";
                break;
            } else {
                langPref = "xx";
            }
        }
        localStorage.setItem("TEMprefLing", langPref);
    }, [])
    
    return (
        <LangContext.Provider
            value={{
                menuOuvert,
                setMenuOuvert
            }}
        >{children}</LangContext.Provider>
    )
}

export default LangContextProvider;