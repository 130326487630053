import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from "react-router-dom";

import { faClose } from "@fortawesome/free-solid-svg-icons";

const anim = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const Menu = ({ manipMenu }) => {

    return (
        <Wrapper>
            <button onClick={manipMenu}>
                <FontAwesomeIcon icon={faClose} />
                <span>Close</span>
            </button>
            <div>
                <ScrollLink
                    offset={-50}
                    onClick={manipMenu}
                    smooth="true"
                    to="section1"
                >Home</ScrollLink>
                <ScrollLink
                    offset={-50}
                    onClick={manipMenu}
                    smooth="true"
                    to="section2"
                >Tours</ScrollLink>
                <ScrollLink
                    offset={-50}
                    onClick={manipMenu}
                    smooth="true"
                    to="section3"
                >FAQ</ScrollLink>
                <ScrollLink
                    offset={-50}
                    onClick={manipMenu}
                    smooth="true"
                    to="section4"
                >Contact</ScrollLink>
            </div>
            <div>
                <Link onClick={manipMenu} to="apropos">About</Link>
                <Link onClick={manipMenu} to="conditions">Terms and Conditions</Link>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.nav`
    animation: ${anim} 0.3s ease-in-out forwards;
    background-color: #2E5339dd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 45px);
    padding: 20px 20% 60px;
    width: 100%;
    > button {
        align-items: center;
        align-self: center;
        background-color: var(--c2);
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        gap: 15px;
        padding: 10px;
        width: fit-content;
    }
    div {
        display: flex;
        flex-wrap: wrap;
        a {
            background: none;
            border: 0;
            color: var(--c0);
            cursor: pointer;
            padding: 15px;
            text-align: center;
            text-decoration: none;
            width: 50%;
            &:hover, &:active {
                background-color: var(--c2);
                color: var(--c5);
            }
        }
        &:first-of-type {
            text-transform: uppercase;
        }
    }
`

export default Menu;