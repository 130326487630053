import styled from "styled-components";

const Prenom = () => {
    return (
        <Wrapper>
            <input
                id="prenom"
                name="prenom"
                required
                type="text"
            />
            <label htmlFor="prenom">Prénom</label>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 450px;
    width: 100%;
    input {
        border-radius: 5px;
        padding: 5px;
    }
    label {
        text-align: center;
    }
`

export default Prenom;