import { ExternalLink } from "react-external-link";

const FAQ2 = () => {
    return (
        <div>
            <h3>Réservation</h3>
            <details>
                <summary>Est-ce que je devrais réserver sur ToursByLocals ou directement avec vous?</summary>
                <p>La plateforme ToursByLocals vous donne l'opportunité de consulter mon calendrier de disponibilités, de choisir entre deux politiques d'annulation, ainsi que d'avoir accès à un guide de remplacement si jamais le vôtre devra annuler pour raison d'urgence. Pour cette raison, c'est la voie à privilégier, sauf pour les réservations de dernière minute (moins de 48 h), où vous pouvez payer sur place.</p>
            </details>
            <details>
                <summary>Si notre vol est annulé, est-il possible d'obtenir un remboursement?</summary>
                <p>Oui, et ceci, peu importe la façon dont vous avez réservé votre tour. Si vous avez payé par la plateforme ToursByLocals, consultez <ExternalLink hrefLang="en" href="https://www.toursbylocals.com/Cancellation-Policy-Overview">leur politique d'annulation (en anglais seulement)</ExternalLink> pour plus de détails.</p>
            </details>
            <details>
                <summary>J'ai réservé une visite du Plateau, mais du coup, ils annoncent beaucoup de pluie. Est-ce que je peux faire la visite de la ville souterraine à la place?</summary>
                <p>Bonne idée! Et certainement! La différence des deux tours vous sera remboursée, si vous avez déjà payé.</p>
            </details>
        </div>
    )
}

export default FAQ2;