import { ExternalLink } from "react-external-link";

const Conditions = () => {
    return (
        <main>
            <h2>Terms and Conditions</h2>
            <p>When sending a message to the author on this website, the information provided by the user will be transmitted. Messages are routed via Formspree <ExternalLink href="https://formspree.io/legal/privacy-policy/">(privacy policy)</ExternalLink>.</p>
            <p>Basic information is collected by <ExternalLink href="https://www.goatcounter.com/">GoatCounter</ExternalLink>, a privacy-friendly alternative to Google Analytics.</p>
            <p>Please note that browsing other websites is subject to acceptance of their terms and conditions. The author declines all responsibility in this respect.</p>
        </main>
    )
}

export default Conditions;