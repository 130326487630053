import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Images from "./Images";
import Carte from "./Carte";
import { faCreditCard, faClock, faLocationDot, faClipboardCheck, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";

const ImagesEtDetails = ({ item }) => {

    return (
        <Wrapper>
            <Images tour={item.tableauImg} photos={item.images} />
            <h4><FontAwesomeIcon icon={faClock} /> <span>Dauer</span></h4>
            <p>{item.duree}</p>
            <h4><FontAwesomeIcon icon={faCreditCard} /> <span>Preis</span></h4>
            {
                item.prix === null
                    ? <p>Der Preis wird je nach Dauer, Inhalt und Anzahl der Personen variieren.</p>
                    : <p>{item.prix} $ für eine Gruppe von 10 Personen oder weniger</p>
            }
            <h4><FontAwesomeIcon icon={faLocationDot} /> <span>Vorgeschlagener Startpunkt</span></h4>
            <ul>
                {
                    item.depart.map((itemD, indexD) => {
                        return (
                            <li key={indexD}>{itemD}</li>
                        )
                    })
                }
            </ul>
            {
                item.titre !== "Personalisierte Tour" &&
                <>
                    <Carte item={item} />
                    <h4><FontAwesomeIcon icon={faClipboardCheck} /> <span>Inklusive</span></h4>
                    <ul>
                        {
                            item.inclus.map((itemI, indexI) => {
                                return (
                                    <li key={indexI}>{itemI}</li>
                                )
                            })
                        }
                    </ul>
                    <h4><FontAwesomeIcon icon={faHandHoldingDollar} /> <span>Extra</span></h4>
                    <ul>
                        {
                            item.exclus.map((itemE, indexE) => {
                                return (
                                    <li key={indexE}>{itemE}</li>
                                )
                            })
                        }
                    </ul>
                </>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: block;
    margin: 0 auto;
    max-width: 450px;
`

export default ImagesEtDetails;