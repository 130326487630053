import styled from "styled-components";

const Message = () => {
    return (
        <Wrapper>
            <label htmlFor="message">You wish to know more? You would like to reserve? Let me know down below!</label>
            <textarea
                id="message"
                name="message"
                required
                rows="10"
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 450px;
    width: 100%;
    textarea {
        border-radius: 5px;
        padding: 5px;
    }
    label {
        text-align: center;
    }
`

export default Message;