const FAQ1 = () => {
    return (
        <div>
            <h3>Les tours offerts</h3>
            <details>
                <summary>L'itinéraire est il fixe ou y a-t-il place à changement?</summary>
                <p>Dans la mesure du possible, l'itinéraire peut être ajusté selon les souhaits du client.</p>
            </details>
            <details>
                <summary>Allons nous voir tout ce qui est sur l'itinéraire?</summary>
                <p>Normalement, oui. Par contre, il se peut que certains attraits soient fermés durant certaines journées. Ils seront remplacés par des attraits semblables au besoin. D'autres imprévus peuvent aussi arriver lors d'un tour.</p>
            </details>
            <details>
                <summary>Est-ce que je peux amener mes enfants?</summary>
                <p>Bien sûr! Qu'ils aient 6 mois ou 16 ans, les visites guidées sont toujours agréables en famille. Il s'agit d'une visite privée, après tout. Je peux adapter le contenu, ou vous donner plus de pauses si nécessaire. Pas de problème si vous avez une poussette aussi.</p>
            </details>
            <details>
                <summary>Et s'il pleut ou neige?</summary>
                <p>À moins d'un cas extrême de météo (tornade, verglas excessif, smog extrême), les visites iront de l'avant. Amenez ce qu'il vous faut pour rester au frais / au chaud / au sec. Nous pourrions aussi faire plus d'arrêts à l'intérieur au besoin.</p>
            </details>
            <details>
                <summary>Est-ce qu'il y a des toilettes le long de la visite?</summary>
                <p>Absolument!</p>
            </details>
            <details>
                <summary>Que devrais-je amener?</summary>
                <p>Une bouteille d'eau remplissable (il y a des points d'eau en chemin), des vêtements adaptés à la météo, de l'argent (comptant ou carte de crédit/débit).</p>
            </details>
        </div>
    )
}

export default FAQ1;