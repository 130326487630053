import { ExternalLink } from "react-external-link";

const Conditions = () => {
    return (
        <main>
            <h2>Conditions d'utilisation</h2>
            <p>Lors de l'envoi d'un message à l'auteur sur ce site web, les informations fournies par l'utilisateur seront transmises. Les messages sont acheminés via Formspree <ExternalLink hrefLang="en" href="https://formspree.io/legal/privacy-policy/">(politique de confidentialité)</ExternalLink>.</p>
            <p>Quelques informations de base sont collectées par <ExternalLink hrefLang="en" href="https://www.goatcounter.com/">GoatCounter</ExternalLink>, une alternative respectueuse de la vie privée à Google Analytics.</p>
            <p>Veuillez noter que la navigation sur d'autres sites web est soumise à l'acceptation de leurs conditions. L'auteur de ce site décline toute responsabilité à cet égard.</p>
        </main>
    )
}

export default Conditions;