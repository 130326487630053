import styled from "styled-components";

import LogoJS from "../FRA/1. Entete/Logo";
import Choix from "./Choix";
import imgAcc from "../images/panorama-montreal.webp";

const App0L = () => {
    return (
        <Wrapper>
            <h1>Tours Explore Montréal</h1>
            <LogoJS />
            <Choix />
            <img src={imgAcc} alt="panorama sur Montréal" />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    align-items: center;
    background-color: var(--c1);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    h1 {
        margin: 0;
        opacity: 0;
        position: absolute;
    }
    svg {
        max-width: 100%;
        min-height: 30vh;
        height: auto;
    }
    img {
        height: 30vh;
        object-fit: cover;
        width: 100%;
    }
`

export default App0L;