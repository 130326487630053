const FAQ1 = () => {
    return (
        <div>
            <h3>Die angebotenen Touren</h3>
            <details>
                <summary>Ist die Route festgelegt oder kann sie geändert werden?</summary>
                <p>Soweit möglich, kann die Strecke nach den Wünschen des Klienten angepasst werden.</p>
            </details>
            <details>
                <summary>Werden wir alles auf der Route sehen?</summary>
                <p>Normalerweise ist das so. Es kann jedoch vorkommen, dass einige Attraktionen an bestimmten Tagen geschlossen sind. Sie werden bei Bedarf durch ähnliche Attraktionen ersetzt. Auch andere unvorhergesehene Situationen können während einer Tour auftreten.</p>
            </details>
            <details>
                <summary>Kann ich meine Kinder mitbringen?</summary>
                <p>Natürlich! Egal ob 6 Monate oder 16 Jahre alt, Stadtführungen sind für Familien immer ein Vergnügen. Das ist ja schließlich eine private Tour. Ich kann den Inhalt anpassen oder Ihnen mehr Pausen geben, wenn es nötig ist. Es ist auch kein Problem, wenn Sie einen Kinderwagen dabei haben.</p>
            </details>
            <details>
                <summary>Was passiert, wenn es regnet oder schneit?</summary>
                <p>Wenn nicht gerade ein extremer Wetterfall eintritt (Tornado, Glatteis, extremer Smog), werden die Besuche weitergehen. Bringen Sie mit, was Sie brauchen, um kühl/warm/trocken zu bleiben. Wir können bei Bedarf auch mehr Stopps drinnen machen.</p>
            </details>
            <details>
                <summary>Gibt es Toiletten entlang der Tour?</summary>
                <p>Absolut!</p>
            </details>
            <details>
                <summary>Was sollte ich mitbringen?</summary>
                <p>Eine auffüllbare Wasserflasche (es gibt Trinkbrunnen auf dem Weg), dem Wetter angepasste Kleidung, Geld (Bargeld oder Kredit-/Debitkarte).</p>
            </details>
        </div>
    )
}

export default FAQ1;