import styled from "styled-components";
import { useContext } from "react";

import { LangContext } from "../../LangContext";
import Boutons from "./Boutons";
import ImgAcc from "./ImgAcc";
import LogoJS from "./Logo";
import Menu from "./Menu";

const Entete = (() => {

    const { menuOuvert, setMenuOuvert } = useContext(LangContext);

    const manipMenu = () => setMenuOuvert((prec) => !prec);

    return (
        <header>
            <Collant>
                <Boutons
                    manipMenu={manipMenu}
                />
                {
                    menuOuvert &&
                    <Menu manipMenu={manipMenu} />
                }
            </Collant>
            <Degrade>
                <LogoJS />
            </Degrade>
            <ImgAcc />
        </header>
    )
})

const Collant = styled.div`
    background-color: var(--c1);
    height: 45px;
    position: fixed;
    width: 100%;
    z-index: 3;
`

const Degrade = styled.div`
    background-image: linear-gradient(var(--c1), #FEFEFC);
    > svg {
        display: block;
        height: fit-content;
        margin: 0 auto;
        max-width: 100%;
        padding-top: 45px;
    }
    @media screen and (max-width: 750px) {
        background-image: linear-gradient(var(--c1), #ECF6FE)
    }
`

export default Entete;