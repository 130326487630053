import { ExternalLink } from "react-external-link";

const FAQ2 = () => {
    return (
        <div>
            <h3>Booking</h3>
            <details>
                <summary>Should I book through ToursByLocals or directly with you?</summary>
                <p>The ToursByLocals platform gives you the opportunity to consult my availability calendar, to choose between two cancellation policies, and to have access to a replacement guide if ever yours has to cancel for emergency reasons. For this reason, it's the way to go, except potentially for last-minute bookings (less than 48 hours), when you can pay on the spot.</p>
            </details>
            <details>
                <summary>If our flight is cancelled, can we get a refund?</summary>
                <p>Yes, regardless of how you booked your tour. If you booked through the ToursByLocals platform, please see <ExternalLink href="https://www.toursbylocals.com/Cancellation-Policy-Overview">their cancellation policy</ExternalLink> for more details.</p>
            </details>
            <details>
                <summary>I've booked a visit to the Plateau, but they're forecasting a lot of rain. Can I visit the underground city instead?</summary>
                <p>Great idea! It certainly is possible! The price difference between the two tours will be refunded, if you've already paid.</p>
            </details>
        </div>
    )
}

export default FAQ2;