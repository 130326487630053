import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBuildingUser } from "@fortawesome/free-solid-svg-icons";

const Section1 = () => {
    return (
        <Wrapper>
            <h2><FontAwesomeIcon icon={faBuildingUser} /> <span>Besuchen Sie Montreal mit einem lokalen Guide!</span></h2>
            <ul>
                <li>Montreal aus einer anderen Perspektive</li>
                <li>Die Stadt mit einem lokalen Stadtführer</li>
                <li>Klassische oder spezialisierte Besuche</li>
                <li>Private Touren</li>
                <li>Personalisierte Touren</li>
                <li>Im Winter wie im Sommer</li>
                <li>Auf Deutsch, Französisch oder Englisch</li>
            </ul>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    ul {
        
    }
`

export default Section1;