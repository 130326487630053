import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBuildingUser } from "@fortawesome/free-solid-svg-icons";

const Section1 = () => {
    return (
        <Wrapper>
            <h2><FontAwesomeIcon icon={faBuildingUser} /> <span>Visit Montreal with a local guide!</span></h2>
            <ul>
                <li>Montreal through another angle</li>
                <li>Point of view of a local guide</li>
                <li>Classic or specialized tours</li>
                <li>Private tours</li>
                <li>Custom-tailored tours</li>
                <li>In winter or in summer</li>
                <li>In English, French, of German</li>
            </ul>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    ul {
        
    }
`

export default Section1;