import styled from "styled-components";

const Nom = () => {
    return (
        <Wrapper>
            <input
                id="nomF"
                name="nomF"
                required
                type="text"
            />
            <label htmlFor="nomF">Nom de famille</label>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 450px;
    width: 100%;
    input {
        border-radius: 5px;
        padding: 5px;
    }
    label {
        text-align: center;
    }
`

export default Nom;