import styled from "styled-components";

const Courriel = () => {
    return (
        <Wrapper>
            <input
                id="email"
                name="email"
                required
                type="email"
            />
            <label htmlFor="email">Adresse courriel</label>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 450px;
    width: 100%;
    input {
        border-radius: 5px;
        padding: 5px;
    }
    label {
        text-align: center;
    }
`

export default Courriel;