import styled from "styled-components";

import { imgAP1 } from "../../images/a-propos";

const APropos = () => {
    return (
        <main>
            <h2>About</h2>
            <p>I am a certified tour guide, and have been since 2015. My license number is 7868.</p>
            <p>My favorite neighborhood is Le Plateau-Mont-Royal. I also enjoy Rosemont and Villeray.</p>
            <p>I love walking and cycling around Montreal, discovering new places.</p>
            <p>I appreciate Montreal for its cultural diversity, its civic initiatives and its nature. Indeed, the many parks and waterways that surround it make the city more pleasant, both in winter and summer.</p>
            <p>My favorite times to enjoy our city? In May, when Montrealers wake up from the long winter, in August to enjoy summer one last time, and during the cold season, just after a snowstorm.</p>
            <p>When I'm not guiding, I'm teaching. I love interacting with people.</p>
            <p>I look forward to showing you my city!</p>
            <p>Nicola Z.</p>
            <ImagesMoi>
                <img alt="me with a river" src={imgAP1} />
            </ImagesMoi>
        </main>
    )
}

const ImagesMoi = styled.div`
    background-color: var(--c2);
    display: flex;
    justify-content: center;
    img {
        max-width: 250px;
    }
`

export default APropos;