import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBuildingUser } from "@fortawesome/free-solid-svg-icons";

const Section1 = () => {
    return (
        <Wrapper>
            <h2><FontAwesomeIcon icon={faBuildingUser} /> <span>Visitez Montréal avec un guide local!</span></h2>
            <ul>
                <li>Montréal sous un autre angle</li>
                <li>La ville par un guide local</li>
                <li>Visites classiques ou spécialisées</li>
                <li>Tours privés</li>
                <li>Tours sur mesure</li>
                <li>En hiver comme à l'été</li>
                <li>En français, en anglais ou en allemand</li>
            </ul>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    ul {
        
    }
`

export default Section1;