import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
        font-family: 'Lexend Deca', "Trebuchet MS", Helvetica, sans-serif;
    }
    body {
        color: var(--c5);
        font-family: 'Lexend Deca', "Trebuchet MS", Helvetica, sans-serif;
        margin: 0;
    }
    main {
        padding: 15px 18px;
        min-height: calc(100vh - 500px);
        @media screen and (min-width: 420px) and (max-width: 800px) {
            padding-right: 36px;
            padding-left: 36px;
        }
        @media screen and (min-width: 800px) and (max-width: 1100px) {
            padding-right: 60px;
            padding-left: 60px;
        }
        @media screen and (min-width: 1100px) {
            padding: 15px 20% 40px;
        }
    }
    section {
        margin-top: 40px;
    }
    h2 {
        text-align: center;
    }
    :root {
        --c6: #000;
        --c5: #3A2E39;
        --c4: #2E5339;
        --c3: #9D8189;
        --c2: #6AD68A;
        --c1: #DDFFF7;
        --c0: #fff;
    }
`