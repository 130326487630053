import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";

import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";
import MenuLang from "./MenuLang";

const Boutons = ({ manipMenu }) => {
    
    const location = useLocation();

    return (
        <Wrapper>
            {
                location.pathname !== "/en" && <button title="home">
                    <Link to="/en"><FontAwesomeIcon icon={faHome} size="2x" /></Link>
                </button>
            }
            {
                location.pathname === "/en" && <button title="open/close menu" onClick={manipMenu}>
                    <FontAwesomeIcon icon={faBars} size="2x" />
                </button>
            }
            <MenuLang />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: var(--c1);
    display: flex;
    justify-content: space-between;
    height: 45px;
    position: relative;
    z-index: 2;
    button {
        background: none;
        border: 0;
        cursor: pointer;
        padding: 5px;
        svg {
            color: var(--c6);
        }
        &:hover {
            background-color: var(--c2);
        }
    }
`

export default Boutons;