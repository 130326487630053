import styled from "styled-components";

import { imgAP1 } from "../../images/a-propos";

const APropos = () => {
    return (
        <main>
            <h2>Über mich</h2>
            <p>Seit 2015 bin ich ein zertifizierter Reiseleiter. Meine Lizenznummer lautet 7868.</p>
            <p>Mein Lieblingsviertel ist Le Plateau-Mont-Royal. Ich mag auch Rosemont und Villeray.</p>
            <p>Ich liebe es, durch Montreal zu spazieren oder mit dem Fahrrad zu fahren und dabei neue Orte zu entdecken.</p>
            <p>Ich schätze Montreal wegen seiner kulturellen Vielfalt, seiner Bürgerinitiativen und seiner Natur. Ja ja, die vielen Parks und die umliegenden Wasserwege machen die Stadt sowohl im Winter als auch im Sommer angenehm.</p>
            <p>Meine Lieblingszeit, um meine Stadt zu genießen? Im Mai, wenn die Menschen nach dem Winter aufwachen, im August, um den Sommer ein letztes Mal zu genießen, und im Winter, am Tag nach einem Schneesturm.</p>
            <p>Wenn ich nicht als Reiseleiter arbeite, bin ich Lehrer. Der Kontakt mit Menschen gefällt mir.</p>
            <p>Ich freue mich darauf, Ihnen meine Stadt zu zeigen!</p>
            <p>Nicola Z.</p>
            <ImagesMoi>
                <img alt="ich mit Fluss" src={imgAP1} />
            </ImagesMoi>
        </main>
    )
}

const ImagesMoi = styled.div`
    background-color: var(--c2);
    display: flex;
    justify-content: center;
    img {
        max-width: 250px;
    }
`

export default APropos;