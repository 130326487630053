import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";

const FormMerci = () => {
    return (
        <Wrapper>
            <FontAwesomeIcon icon={faEnvelopeCircleCheck} size="3x" />
            <p>Vielen Dank, dass Sie sich mit Tours Explore Montreal in Kontakt gesetzt haben. Sie werden so bald wie möglich eine Antwort erhalten.</p>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    border: 2px solid var(--c5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin: 0 2px;
    padding: 40px 20px;
    p {
        text-align: center;
    }
`

export default FormMerci;