import styled from "styled-components";
import { MapContainer, TileLayer, Polygon, Popup, Marker } from 'react-leaflet';

const Carte = ({ item }) => {

    return (
        <Wrapper aria-hidden>
            <CarteCont center={item.carteCentre} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={item.carteDepart} pathOptions={{ color: 'var(--c4)' }}>
                    <Popup>Departure at your hotel located in this zone</Popup>
                </Polygon>
                {
                    item.lieuxDepart.map((itemL, indexL) => {
                        return (
                            <Marker key={indexL} position={itemL}>
                                <Popup>Suggested starting point: {item.depart[indexL]}</Popup>
                            </Marker>
                        )
                    })
                }
            </CarteCont>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    > div {
        position: relative;
        z-index: 0;
        .leaflet-control-zoom a,
        .leaflet-control-attribution a {
            color: var(--c4);
        }
    }
`

const CarteCont = styled(MapContainer)`
    height: 300px;
    width: 100%;
`

export default Carte;