import styled from "styled-components";
import { ExternalLink } from "react-external-link";
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTicket } from "@fortawesome/free-solid-svg-icons";

const BoiteReserv = ({ tour }) => {

    return (
        <Wrapper>
            <h4><FontAwesomeIcon icon={faTicket} /> <span>Réservation</span></h4>
            <p>Comment souhaitez-vous payer?</p>
            <Boutons>
                <li>
                    <ExternalLink hrefLang="en" href={tour.tbl}>À l'avance</ExternalLink>
                    <ul>
                        <li>Réservez sur ToursByLocals (site en anglais seulement)</li>
                        <li><ExternalLink hrefLang="en" href="https://www.toursbylocals.com/Cancellation-Policy-Overview">Politique d'annulation</ExternalLink> en place</li>
                    </ul>
                </li>
                <li>
                    <ScrollLink smooth="true" to="section4">Sur place</ScrollLink>
                    <ul>
                        <li>Pour les visites dans 48 heures ou moins seulement</li>
                        <li>Payez par virement interac au début de la visite</li>
                        <li>Pas de frais d'annulation</li>
                    </ul>
                </li>
            </Boutons>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    h4, p {
        text-align: center;
    }
`

const Boutons = styled.ul`
    display: flex;
    gap: 20px;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    text-align: center;
    @media screen and (max-width: 550px) {
        flex-direction: column;
        
    }
    > li {
        > a {
            background-color: var(--c4);
            border-radius: 5px;
            border: 2px outset buttonborder;
            color: var(--c1);
            cursor: pointer;
            display: block;
            font-size: small;
            margin: 10px auto;
            padding: 5px 10px;
            text-decoration: none;
            width: fit-content;
            &:hover {
                background-color: var(--c3);
                color: var(--c6);
            }
        }
    }
    ul {
        font-size: small;
        list-style: square inside;
        padding-left: 0px;
    }
`

export default BoiteReserv;