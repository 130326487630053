import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import AppFr from "./FRA/AppFr";
import AppEn from "./ENG/AppEn";
import AppDe from "./DEU/AppDe";
import App0L from "./XX/App0L";
import Chargement from "./Chargement";
import GlobalStyles from "./GlobalStyles";

const App = () => {

    const [pret, setPret] = useState(false);

    const urlPresent = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("TEMprefLing") && window.location.pathname === "/") {
            urlPresent(localStorage.TEMprefLing)
        }
        setPret(true);
    }, [])

    if (pret) {
        return (
            <HelmetProvider>
                <GlobalStyles />
                <Routes>
                    <Route path="/fr/*" element={<AppFr />} />
                    <Route path="/en/*" element={<AppEn />} />
                    <Route path="/de/*" element={<AppDe />} />
                    <Route path="/" element={<App0L />} />
                    <Route></Route>
                </Routes>
            </HelmetProvider>
        )
    } else {
        return (
            <>
                <GlobalStyles />
                <Chargement />
            </>
        )
    }
}

export default App;