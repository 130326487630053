const FAQ1 = () => {
    return (
        <div>
            <h3>The tours</h3>
            <details>
                <summary>Is the itinerary fixed or is it open to change?</summary>
                <p>Where possible, the itinerary can be adjusted according to the customer's wishes.</p>
            </details>
            <details>
                <summary>Are we going to see everything on the itinerary?</summary>
                <p>Normally, yes. However, some tourist attractions may be closed on certain days. They will be replaced by similar sites if necessary. Other unforeseen events may also occur during a tour.</p>
            </details>
            <details>
                <summary>Can I bring my children with me?</summary>
                <p>Of course you can! Whether they're 6 months or 16 years old, guided tours are always a fun family activity. It's a private tour, after all. I can adapt the content, or give you more breaks if necessary. No need to worry if you've got a stroller too.</p>
            </details>
            <details>
                <summary>What if it rains or snows?</summary>
                <p>Barring extreme weather (tornado, excessive ice, extreme smog), visits will go on as planned. Bring what you need to stay cool/warm/dry. We could also make more indoor stops if necessary.</p>
            </details>
            <details>
                <summary>Are there restrooms along the tour?</summary>
                <p>Absolutely!</p>
            </details>
            <details>
                <summary>What should I bring?</summary>
                <p>A refillable water bottle (there are drinking fountains along the way), weather-appropriate clothing, money (cash or credit/debit card).</p>
            </details>
        </div>
    )
}

export default FAQ1;