import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import * as imgCV from "../../../../images/centre-ville/index";
import * as imgVM from "../../../../images/vieux-montreal/index";
import * as imgPL from "../../../../images/plateau/index";
import * as imgMS from "../../../../images/sur-mesure/index";

const Images = ({ tour, photos }) => {

    const [imageMontrer, setImageMontrer] = useState(0);

    const imgPrec = () => {
        imageMontrer === 0 ? setImageMontrer(6) : setImageMontrer(imageMontrer - 1)
    }

    const imgSuiv = () => {
        imageMontrer === 6 ? setImageMontrer(0) : setImageMontrer(imageMontrer + 1)
    }

    return (
        <Wrapper>
            <BoutonPrec onClick={imgPrec} title="vorheriges Bild">
                <FontAwesomeIcon icon={faChevronLeft} size="2x" />
            </BoutonPrec>
            {
                tour === "imgCV" && <img alt={photos[imageMontrer].alt} src={imgCV[photos[imageMontrer].src]} />
            }
            {
                tour === "imgVM" && <img alt={photos[imageMontrer].alt} src={imgVM[photos[imageMontrer].src]} />
            }
            {
                tour === "imgPL" && <img alt={photos[imageMontrer].alt} src={imgPL[photos[imageMontrer].src]} />
            }
            {
                tour === "imgMS" && <img alt={photos[imageMontrer].alt} src={imgMS[photos[imageMontrer].src]} />
            }
            <BoutonSuiv onClick={imgSuiv} title="folgendes Bild">
                <FontAwesomeIcon icon={faChevronRight} size="2x" />
            </BoutonSuiv>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
    z-index: 1;
    button {
        background-color: #3A2E3988;
        border: none;
        cursor: pointer;
        height: 60px;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        &:hover {
            background-color: #9D818988;
        }
        svg {
            color: var(--c0);
        }
    }
    img {
        width: 100%;
    }
`

const BoutonPrec = styled.button`
    left: 0;
`

const BoutonSuiv = styled.button`
    right: 0;
`

export default Images;